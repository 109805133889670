import React, {useState} from 'react';
import {Divider, Modal, Spin} from "antd";
import CodeEditor from "../../../components/CodeEditor/CodeEditor";

interface EditFileProps {
    selectedFile: SelectedFileSchema;
    handleModalClose: () => void;
    handleModalOK: (key: string, newContent: string) => void;
    isModalOpen: boolean;
    loading?: boolean;
}

export interface SelectedFileSchema {
    filename: string;
    extension: string;
    path: string;
    content: string;
}

function EditFileModal({ selectedFile, handleModalClose, isModalOpen, loading, handleModalOK }: EditFileProps) {
    const [fileContent, setFileContent] = useState(selectedFile.content);

    return (
        <Modal
            width={'90%'}
            title={<span>Documento: <span style={{ color: 'red' }}>{selectedFile.filename}</span></span>}
            open={isModalOpen}
            onCancel={handleModalClose}
            onOk={() => { handleModalOK(selectedFile.path, fileContent) }}
            maskClosable={false}
            destroyOnClose
            okText="Guardar"
        >
            <Spin spinning={loading}>
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <CodeEditor extension={selectedFile.extension} fileContent={selectedFile.content} setFileContent={setFileContent}/>
            </Spin>
        </Modal>
    );
}

export default EditFileModal;
