import React, {ReactNode, useState} from 'react';
import {
    Avatar,
    Breadcrumb,
    ConfigProvider, Drawer,
    Dropdown,
    Flex,
    Layout as AntdLayout,
    Menu,
    MenuProps, Row,
    Space,
    theme
} from 'antd';
import {
    DollarOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined
} from '@ant-design/icons';
import AuthAction from "../../redux/actions/authActions/Actions";
import {useDispatch, useSelector} from "react-redux";
import {User} from "../../models/User";
import {Link} from "react-router-dom";
import {AuthService} from "../../services/AuthService";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {menuConfig} from "../../config/MenuConfig";
import {ItemType} from "antd/es/breadcrumb/Breadcrumb";
import esES from 'antd/locale/es_ES';
import Title from "antd/es/typography/Title";
import { useMediaQuery } from 'react-responsive';
import { MenuStore } from '../../redux/reducers/MenuReducer';
import { useThemeLayoutConfig } from '../../config/ThemeDashboardLayoutConfig';
import MenuAction from '../../redux/actions/menuActions/Action';

import './Layout.scss';

interface LayoutProps {
    children: ReactNode;
    loading?: boolean;
    title?: string;
    breadcrumb?: ItemType[];
}

const { Header, Content, Sider } = AntdLayout;

function Layout({ children, loading = false, title = '', breadcrumb = [] }: LayoutProps) {
   const themeConfig = useThemeLayoutConfig();

    const dispatch = useDispatch();
    const authUser: User = useSelector((state: any) => state.auth);
    const { selectedKeys, openKeys }: MenuStore = useSelector((state: any) => state.menu);
    const [localLoading, setLocalLoading] = useState(false);
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

    const [isToggled, setToggled] = useState(false);
    const toggleTrueFalse = () => setToggled(!isToggled);
    const onClose = () => {
        setToggled(false);
    };

    const logout = async () => {
        setLocalLoading(true);

        if (authUser.token) {
            await AuthService.logout();
        }

        dispatch(AuthAction.signOut({}));
        setLocalLoading(false);
    }

    const items: MenuProps['items'] = [
        {
            label: <a onClick={() => { console.log("Perfil"); }}><UserOutlined style={{ marginRight: '7.5px' }}/> Perfil</a>,
            key: '1',
        },
        {
            label: <a onClick={() => { console.log("Suscripción"); }}><DollarOutlined style={{ marginRight: '7.5px' }} /> Suscripción</a>,
            key: '2',
        },
        {
            type: 'divider'
        },
        {
            label: <a onClick={() => { logout(); }}><LogoutOutlined style={{ marginRight: '7.5px' }} /> Cerrar sesión</a>,
            key: '3',
        },
    ];

    return (
        <ConfigProvider
            theme={themeConfig}
            locale={esES}
        >
            <LoadingScreen loading={(loading || localLoading)}/>
            <AntdLayout style={{ minHeight: '100vh' }}>
                {
                    !isDesktop && (
                        <Drawer
                            placement="left"
                            onClose={onClose}
                            closable={false}
                            open={isToggled}
                            bodyStyle={{ backgroundColor: themeConfig.token?.colorPrimary || '#FFF', padding: "0" }}
                            width="80%"
                        >
                            <Menu
                                mode="inline"
                                style={{ height: '100%', borderRight: 0 }}
                                selectedKeys={selectedKeys}
                                openKeys={openKeys}
                                forceSubMenuRender
                                onOpenChange={(openKeysUpdated) => {
                                    dispatch(MenuAction.update({ openKeys: openKeysUpdated }));
                                }}
                            >
                                {
                                    menuConfig(authUser).menus.map((menu) => (
                                        menu.children ? (
                                            <Menu.SubMenu title={menu.label} key={menu.key} icon={menu.icon} disabled={menu.disabled}>
                                                {
                                                    menu.children.map((submenuItem: any) => (
                                                        <Menu.Item key={submenuItem.key} icon={submenuItem.icon} disabled={submenuItem.disabled} style={{ minWidth: '235px' }}>
                                                            {
                                                                submenuItem.path && !submenuItem.disabled ? (<Link to={submenuItem.path}>{submenuItem.label}</Link>) : <span>{submenuItem.label}</span>
                                                            }
                                                        </Menu.Item>
                                                    ))
                                                }
                                            </Menu.SubMenu>
                                        ) : (
                                            <Menu.Item key={menu.key} icon={menu.icon} disabled={menu.disabled}>
                                                {
                                                    menu.path && !menu.disabled ? (<Link to={menu.path}>{menu.label}</Link>) : <span>{menu.label}</span>
                                                }
                                            </Menu.Item>
                                        )
                                    ))
                                }
                            </Menu>
                        </Drawer>
                    )
                }
                <Header style={{ backgroundColor: themeConfig.token?.colorPrimary }}className="custom-nav-header">
                    <Flex gap="middle" justify="space-between" style={{ width: '100%' }}>
                        { !isDesktop && React.createElement(
                            isToggled ? MenuUnfoldOutlined : MenuFoldOutlined,
                            {
                                className: "trigger-menu-layout",
                                onClick: toggleTrueFalse,
                            }
                        )}

                        <Flex gap="middle" justify="space-between" align="center" style={{ cursor: "pointer" }}>
                            <img
                                className="logo"
                                src="/logo_touchlatam_white.png"
                                style={{ height: '30px' }}
                            />
                        </Flex>
                        <Space direction="vertical">
                            <Space wrap>
                                <Dropdown
                                    menu={{ items }}
                                    trigger={["click"]}
                                    placement="bottomLeft"
                                    arrow
                                >
                                    <div onClick={e => e.preventDefault()}>
                                        <Space size={16} wrap>
                                            <Avatar style={{ backgroundColor: '#cfe8fc', color: '#0077cc', cursor: 'pointer' }}><span style={{ textTransform: 'uppercase' }}>{ authUser.name.slice(0, 1) + authUser.lastname.slice(0, 1) }</span></Avatar>
                                        </Space>
                                    </div>
                                </Dropdown>
                            </Space>
                        </Space>
                    </Flex>
                </Header>
                <AntdLayout>
                    {
                        isDesktop && (
                            <Sider
                                width={320}
                                style={{ background: themeConfig.token?.colorBgContainer }}
                                collapsedWidth={0}
                                collapsed={false}
                            >
                                <Menu
                                    mode="vertical"
                                    style={{ height: '100%', borderRight: 0 }}
                                    selectedKeys={selectedKeys}
                                    openKeys={openKeys}
                                    forceSubMenuRender
                                    onOpenChange={(openKeysUpdated) => {
                                        dispatch(MenuAction.update({ openKeys: openKeysUpdated }));
                                    }}
                                >
                                    {
                                        menuConfig(authUser).menus.map((menu) => (
                                            menu.children ? (
                                                <Menu.SubMenu title={menu.label} key={menu.key} icon={menu.icon} disabled={menu.disabled}>
                                                    {
                                                        menu.children.map((submenuItem: any) => (
                                                            <Menu.Item key={submenuItem.key} icon={submenuItem.icon} disabled={submenuItem.disabled} style={{ minWidth: '235px' }}>
                                                                {
                                                                    submenuItem.path && !submenuItem.disabled ? (<Link to={submenuItem.path}>{submenuItem.label}</Link>) : <span>{submenuItem.label}</span>
                                                                }
                                                            </Menu.Item>
                                                        ))
                                                    }
                                                </Menu.SubMenu>
                                            ) : (
                                                <Menu.Item key={menu.key} icon={menu.icon} disabled={menu.disabled}>
                                                    {
                                                        menu.path && !menu.disabled ? (<Link to={menu.path}>{menu.label}</Link>) : <span>{menu.label}</span>
                                                    }
                                                </Menu.Item>
                                            )
                                        ))
                                    }
                                </Menu>
                            </Sider>
                        )
                    }
                    <AntdLayout style={{ padding: '18px 24px' }}>
                        {
                            (breadcrumb.length > 0 || title) && (
                                <Flex gap="small" justify="flex-start" vertical className="dashboard-layout-header-container">
                                    {
                                        breadcrumb.length > 0 && (
                                            <Flex gap="middle" justify="flex-start" className="dashboard-layout-breadcrumb">
                                                <Breadcrumb
                                                    separator=">"
                                                    items={breadcrumb}
                                                />
                                            </Flex>
                                        )
                                    }
                                    {
                                        title && (
                                            <Flex gap="middle" justify="flex-start" className="dashboard-layout-title">
                                                <Title level={3} style={{ textTransform: 'uppercase', marginBottom: '0' }}>{title}</Title>
                                            </Flex>
                                        )
                                    }
                                </Flex>
                            )
                        }
                        {children}
                    </AntdLayout>
                </AntdLayout>
            </AntdLayout>
        </ConfigProvider>
    );
}

export default Layout;
