import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FileModel} from "../models/FileModel";
import {FileModelType} from "../config/Constants";

export const MainService = {
    bucketList: async (prefix: string = ''): Promise<ApiResponse<FileModel[] | ApiErrorData>>  => {
        prefix = prefix.trim();
        try {
            const response = await MainBackendAxios.get(prefix ? `/solimain?prefix=${prefix}` : '/solimain', {
                headers: {
                    'service': 'prod'
                }
            });
            const data = response?.data?.data || [];

            return {
                success: true,
                status: response.status,
                data: data.map((record: string): FileModel => {
                    const path = FunctionsHelper.cleanPath(record.trim());
                    const isFolder = path.endsWith('/');
                    let type = FileModelType.DOCUMENT;
                    let extension: string | undefined = undefined;

                    if (isFolder) {
                        type = FileModelType.FOLDER;
                    } else {
                        type = FileModelType.DOCUMENT;
                        extension = path.split('.').pop()?.toLowerCase();
                    }

                    const fullPath = prefix ? `${prefix}${path}` : path;

                    return {
                        key: path,
                        type: type,
                        extension: extension,
                        breadCrumb: FunctionsHelper.generateBreadcrumb(fullPath),
                        fullPath: fullPath
                    };
                }),
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getFileContent: async (filePath: string): Promise<ApiResponse<string | ApiErrorData>>  => {
        try {
            const response = await MainBackendAxios.post('/solimain', { prefix: filePath }, {
                headers: {
                    'service': 'prod'
                }
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data || '',
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    saveFileContent: async (filePath: string, content: string): Promise<ApiResponse<{ location: string } | ApiErrorData>>  => {
        try {
            const response = await MainBackendAxios.put('/solimain', { prefix: filePath, file: content }, {
                headers: {
                    'service': 'prod'
                }
            });

            return {
                success: true,
                status: response.status,
                data: {
                    location: response?.data?.data?.Location || ''
                }
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getRepositories: async (): Promise<ApiResponse<string[] | ApiErrorData>>  => {
        try {
            const response = await MainBackendAxios.get('/solimain/repos/touch', {
                headers: {
                    'service': 'prod'
                }
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data || []
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    runGithubAction: async (repository: string, branch: string): Promise<ApiResponse<any | ApiErrorData>>  => {
        try {
            const response = await MainBackendAxios.post('/solimain/run', {
                key: 'touch',
                repository,
                branch
            }, {
                headers: {
                    'service': 'prod'
                }
            });

            return {
                success: true,
                status: response.status,
                data: response?.data?.data
            };
        } catch (err) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
}
