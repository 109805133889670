import { ApiErrorData, ApiResponse } from "../models/ApiResponse";
import axios, { AxiosError } from "axios";
import { MenuItem } from "../models/MenuItem";
import {ItemInterface} from "../models/ItemInterface";

export const FunctionsHelper = {
  getErrorFormat: (error: any, fakeError?: boolean): ApiResponse<ApiErrorData> => {
    if(fakeError) {
      return {
        success: false,
        status: 500,
        data: {
          code: 'fake-error',
          message: error.message || 'Error generico'
        },
      }
    }

    console.error(error);

    let status = 500;
    let errorCode = "GENERIC_ERROR";
    let errorMessage = "Error de red, no se pudo conectar al servidor.";
    let payload = undefined;

    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const dataError = axiosError.response?.data
          ? axiosError.response?.data
            ? (axiosError.response?.data as any).data
            : undefined
          : undefined;

        if (dataError) {
          //Puede ser un string o un objeto con este formato: { code: string, message: string, payload?: number | string };
          if (typeof dataError === "string") {
            errorCode = "VALIDATION_OR_OTHER_ERROR";
            errorMessage = dataError;
          } else {
            // Si dataError es un objeto, extraemos sus propiedades para asignar el código, mensaje y carga útiles
            errorCode = dataError.code || "UNKNOWN_CODE_ERROR";
            errorMessage =
              dataError.message || "Ocurrió un error en el servidor";
            payload = dataError.payload || payload;
          }
        } else {
          errorCode = "API_BAD_FORMAT_ERROR";
          errorMessage =
            "Error, obtuvo una respuesta sin el formato correcto desde servidor.";
        }

        // Error de respuesta del servidor (4xx o 5xx)
        console.log(
          `Error: ${axiosError.response.status} - ${axiosError.response.statusText}`,
          dataError
        );
      } else if (axiosError.request) {
        // Error de solicitud (sin respuesta del servidor)
        errorCode = "SERVER_NO_RESPONSE_ERROR";
        errorMessage =
          "Error de solicitud, no se recibió respuesta del servidor.";
      } else {
        // Otros errores
        errorCode = "APPLICATION_PROCESS_ERROR";
        errorMessage = "Error al procesar la solicitud.";
      }
    }

    return {
      success: false,
      status,
      data: {
        code: errorCode,
        message: errorMessage,
        payload,
      },
    };
  },
  validateEmail: (email: string):boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  },
  sleep: async (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  },
  flattenMenuArrayDashboardMenu: (menuArray: MenuItem[], parentKeys: string[] = []) => {
    let flattenedArray: any[] = [];
    menuArray.forEach((menu: any) => {
      const localParentKeys = [...parentKeys];
      const newMenu = menu;
      localParentKeys.push(newMenu.key);
      newMenu.keys = localParentKeys;
      flattenedArray.push(newMenu);

      if (menu.children && menu.children.length > 0) {
        flattenedArray = flattenedArray.concat(FunctionsHelper.flattenMenuArrayDashboardMenu(menu.children, localParentKeys));
      }
    });
    return flattenedArray;
  },
  cleanPath: (path: string): string => {
    return path.replace(/\/{2,}/g, '/');
  },
  generateBreadcrumb: (path: string): ItemInterface[] => {
    if (path.endsWith("/")) {
      path = path.replace(/\/$/, "");
    }

    const parts = FunctionsHelper.cleanPath(path).split('/');
    const breadcrumb: any[] = [];

    let accumulatedPath = '';
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      accumulatedPath += (i > 0 ? '/' : '') + part;

      breadcrumb.push({
        key: accumulatedPath,
        label: (i === parts.length - 1 && part.indexOf('.') !== -1) ? part : part + '',
      });
    }

    return breadcrumb;
  }
};
