import { Permission } from '../models/Permission';
import {User} from "../models/User";

export const hasRoutePermissions = (
  permissions: string | string[],
  currentUser: any
) => {
  if (currentUser.id === 0) {
    return false;
  }

  const currentPermissions: Permission[] = currentUser.permissions || [];

  if (typeof permissions === 'string') {
    permissions = [permissions];
  }

  if (permissions.length === 0) {
    return true;
  } else {
    let check = 0;

    for (let permission of permissions) {
      permission = permission.trim().toUpperCase();

      if (
        currentPermissions.find((currentPermission: Permission) => {
          return currentPermission.code === permission;
        })
      ) {
        check++;
      }
    }

    return check === permissions.length;
  }
};

export const getFormattedUserData = (userData: any): User => {
  return {
    id: userData._id,
    username: userData.email,
    userType: userData.user_type,
    name: userData.name,
    lastname: userData.last_name,
    email: userData.email,
    createdAt: userData.createdAt,
    token: userData.token,
    permissions: userData.permissions
        ? userData.permissions.map((permission: any) => {
          return {
            code: permission.code,
            name: permission.name,
            description: permission.description,
            createdAt: permission.createdAt,
          };
        })
        : [],
    roles: userData.roles
        ? userData.roles.map((role: any) => {
          return {
            id: role.id,
            name: role.name,
            description: role.description,
            createdAt: role.createdAt,
          };
        })
        : []
  };
}
