import MainBackendAxios from '../utils/MainBackendAxios';
import { User } from '../models/User';
import { LoginDto } from '../models/dtos/LoginDto';
import {ApiErrorData, ApiResponse} from "../models/ApiResponse";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {getFormattedUserData} from "../utils/AuthUtils";

export const AuthService = {
  login: async (loginParams: LoginDto): Promise<ApiResponse<User | ApiErrorData>>  => {
    try {
      const response = await MainBackendAxios.post(`/solimain/login`, { email: loginParams.username, password: loginParams.password });
      const userData = response?.data?.data;

      return {
        success: true,
        status: 200,
        data: getFormattedUserData(userData),
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  verifySession: async (): Promise<ApiResponse<User | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.get(`/solimain/verify`);
      const userData = response.data.data;

      return {
        success: true,
        status: 200,
        data: getFormattedUserData(userData),
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  logout: async (): Promise<ApiResponse<ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post("/auth/logout");
      console.log("response logout", response);
      return {
        success: true,
        status: response.status,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
};
