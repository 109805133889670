import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {User} from "../../models/User";
import {DEFAULT_DASHBOARD_ROUTE} from "../../config/Config";

type PrivateRouteProps = {
  outlet: JSX.Element;
};

function GuestRoute({ outlet }: PrivateRouteProps) {
  const user: User = useSelector((state: any) => state.auth);

  if (user?.id === 0) {
    return outlet;
  }

  return <Navigate to={DEFAULT_DASHBOARD_ROUTE} />;
}

export default GuestRoute;