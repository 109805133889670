import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import {
    GithubOutlined, PlayCircleOutlined
} from "@ant-design/icons";
import {Breadcrumb, Button, Card, Checkbox, Form, Input, List, message, Modal, Select, Typography} from "antd";
import {MainService} from "../../services/MainService";
import {ApiErrorData} from "../../models/ApiResponse";
import {Repository} from "../../models/Repository";

import './GitHubAction.scss';

function GitHubAction() {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [githubActionLoading, setGithubActionLoading] = useState(false);
    const [dataSource, setDataSource] = useState<Repository[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoading(true);

        const getRepositoriesResponse = await MainService.getRepositories();

        if(getRepositoriesResponse.success) {
            const localDataSource = (getRepositoriesResponse.data as string[]).map((record): Repository => {
                return {
                    key: record,
                    branches: [
                        'master',
                        'develop'
                    ]
                }
            });
            setDataSource(localDataSource);
        }else {
            const error = getRepositoriesResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al realizar la acción, por favor inténtalo nuevamente.', 3.5);
        }

        setLoading(false);
    }

    const onFinish = async (values: any) => {
        if(!values.branch) {
            messageApi.error('Debe de seleccionar una rama', 3.5);
        }else {
            const repository = values.branch.split('||||')[0];
            const branch = values.branch.split('||||')[1];


            Modal.confirm({
                title: 'Confirmar',
                cancelText: 'No',
                content:
                    <span>¿Está seguro que quiere ejecutar la github action para la rama <b>{branch}</b> del repositorio <b>{repository}</b>?</span>,
                onOk: async () => {
                    const loadingMessageKey = 'loading-message';
                    messageApi.open({
                        key: loadingMessageKey,
                        type: 'loading',
                        content: 'Ejecutando acción...',
                        duration: 0
                    });

                    setGithubActionLoading(true);

                    const githubActionRunResponse = await MainService.runGithubAction(repository, branch);

                    if(githubActionRunResponse.success) {
                        messageApi.destroy(loadingMessageKey);
                        messageApi.success(<span>Se ejecutó la github action para la rama <b>{branch}</b> del repositorio <b>{repository}</b> de manera satisfactoria.</span>)
                    }else {
                        messageApi.destroy(loadingMessageKey);
                        const error = githubActionRunResponse.data as ApiErrorData;
                        messageApi.error(error.message as string || 'Hubo un error al realizar la acción, por favor inténtalo nuevamente.', 3.5);
                    }
                    setGithubActionLoading(false);
                }
            });
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };

    return (
        <>
            { contextHolder }

            <Layout breadcrumb={[
                { title: <span><GithubOutlined /> Github Actions</span> }
            ]}>
                <Card loading={loading}>
                    <List
                        <Repository>
                        header={<b>Repositorios disponibles</b>}
                        bordered
                        dataSource={dataSource}
                        renderItem={(item) => (
                            <List.Item key={item.key} className="github-action-row">
                                <Typography>{item.key}</Typography>

                                <Form
                                    className="github-action-form"
                                    name={`github-action-form-${item.key}`}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Form.Item
                                        name="branch"
                                        style={{ marginBottom: '0' }}
                                    >
                                        <Select placeholder="-Seleccione la rama-" style={{ width: '185px' }} allowClear size="small">
                                            {
                                                item.branches.map((branchRow) => (
                                                    <Select.Option key={`${item.key}||||${branchRow}`}>{branchRow}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        style={{ marginBottom: '0' }}
                                    >
                                        <Button type="primary" htmlType="submit" size="small">
                                            <PlayCircleOutlined />
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </List.Item>
                        )}
                        loading={githubActionLoading}
                    />
                </Card>
            </Layout>
        </>
    );
}

export default GitHubAction;
