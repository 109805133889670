import {theme, ThemeConfig} from "antd";
import {useSelector} from "react-redux";

export const useThemeLayoutConfig = (): ThemeConfig => {
    const themeToken = theme.useToken();

    const customThemeConfig: ThemeConfig =  {
        token: {
            colorPrimary: "#707070",
        },
        components: {
            Menu: {
                itemSelectedBg: '#cfe8fc',
                itemActiveBg: '#cfe8fc',
                itemSelectedColor: '#1677ff'
            }
        },
    };

    return {
        ...themeToken,
        ...customThemeConfig,
    };
};
