import React from 'react';
import { useSelector } from 'react-redux';
import { User } from '../../models/User';
import Layout from "../../components/Layout/Layout";
import {HomeOutlined} from "@ant-design/icons";
import {Card} from "antd";

import './Home.scss';

function Home() {
  const user: User = useSelector((state: any) => state.auth);
  return (
      <Layout title="Home" breadcrumb={[
          { title: <span><HomeOutlined/> Home</span> }
      ]}>
          <Card>
              <p>
                  Bievenido usuario:{' '}
                  <b>
                      {user.name} {user.lastname}
                  </b>
              </p>
          </Card>
      </Layout>
  );
}

export default Home;
