import {useEffect} from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import GuestRoute from './components/GuestRoute';
import PrivateRoute from './components/PrivateRoute';
import Home from '../scenes/Home/Home';
import Login from '../scenes/Login/Login';
import NotFound from '../scenes/NotFound/NotFound';
import {User} from "../models/User";
import {useDispatch, useSelector} from "react-redux";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import { DEFAULT_DASHBOARD_ROUTE } from '../config/Config';
import { menuConfig } from '../config/MenuConfig';
import MenuAction from '../redux/actions/menuActions/Action';
import FileEditor from "../scenes/FileEditor/FileEditor";
import GitHubAction from "../scenes/GitHubAction/GitHubAction";

export default function AppRoutes() {
    const location = useLocation();
    const dispatch = useDispatch();
    const user: User = useSelector((state: any) => state.auth);

    useEffect(() => {
        const localMenus = FunctionsHelper.flattenMenuArrayDashboardMenu([...menuConfig(user).menus]);
        const localSelectedKey = localMenus.find((row) => {
            const path = (row.path || '').toLowerCase();
            return location.pathname === path;
        });

        if(localSelectedKey) {
            dispatch(MenuAction.update({ selectedKeys: localSelectedKey.keys }));

        }else {
            dispatch(MenuAction.remove());
        }
    }, [location]);

  return (
    <Routes>
        <Route path="/" element={<Navigate to={DEFAULT_DASHBOARD_ROUTE} />} />
        <Route path="/home" element={<PrivateRoute outlet={<Home />} />} />
        <Route path="/file-editor" element={<PrivateRoute outlet={<FileEditor />} />} />
        <Route path="/github-action" element={<PrivateRoute outlet={<GitHubAction />} />} />
        <Route path="/login" element={<GuestRoute outlet={<Login />} />} />
        <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
