import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import {FileOutlined, FileTextOutlined, FolderOpenOutlined} from "@ant-design/icons";
import {Breadcrumb, Card, List, message} from "antd";
import {MainService} from "../../services/MainService";
import {FileModel} from "../../models/FileModel";
import {FileModelType} from "../../config/Constants";
import EditFileModal, {SelectedFileSchema} from "./components/EditFileModal";
import {ApiErrorData} from "../../models/ApiResponse";

import './FileEditor.scss';

function FileEditor() {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [files, setFiles] = useState<FileModel[]>([]);
    const [currentFile, setCurrentFile] = useState<FileModel | undefined>(undefined);
    const [currentSelectedFile, setCurrentSelectedFile] = useState<SelectedFileSchema | undefined>(undefined);
    const [isEditFileModalOpen, setIsEditFileModalOpen] = useState(false);
    const [loadingEditFileModal, setLoadingEditFileModal] = useState(false);

    useEffect(() => {
        if(initLoading) {
            init();
        }
    }, [currentFile]);

    const init = async () => {
        const bucketListResponse = await MainService.bucketList();

        if(bucketListResponse.success) {
            const bucketData = bucketListResponse.data as FileModel[];
            setFiles(bucketData);
            setInitLoading(false);
        }else {
            console.log("Error bucketListResponse", bucketListResponse);
            const error = bucketListResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al realizar la acción, por favor inténtalo nuevamente.', 3.5);
        }
    }

    const fetchFile = async (path: string) => {
        const bucketListResponse = await MainService.bucketList(path);

        if(bucketListResponse.success) {
            const bucketData = bucketListResponse.data as FileModel[];
            setFiles(bucketData);
            setLoading(false);
        }else {
            console.log("Error bucketListResponse", bucketListResponse);
            const error = bucketListResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al realizar la acción, por favor inténtalo nuevamente.', 3.5);
        }
    }

    const selectFile = async (file: FileModel) => {
        if(!loading) {
            setLoading(true);

            if(file.type === FileModelType.FOLDER) {
                await fetchFile(file.fullPath);
                setCurrentFile(file);
            }else if(file.type === FileModelType.BACK_FOLDER) {
                if(currentFile) {
                    if(currentFile.breadCrumb.length > 1) {
                        const temp = currentFile.breadCrumb.slice(0, currentFile.breadCrumb.length - 1);
                        const previousPath = temp.map((record) => { return record.label }).join('/') + '/';
                        await fetchFile(previousPath);
                        setCurrentFile({
                            ...file,
                            breadCrumb: temp
                        })
                    }else {
                        await fetchFile('');
                        setCurrentFile(undefined);
                    }

                }
                setLoading(false);
            }else {
                showEditFileModal(file);
            }
        }
    }

    const showEditFileModal = async (file: FileModel) => {
        setLoadingEditFileModal(true);

        const fileContentResponse = await MainService.getFileContent(file.fullPath);

        if(fileContentResponse.success) {
            setCurrentSelectedFile({
                filename: file.key,
                extension: file.extension || '',
                content: fileContentResponse.data as string,
                path: file.fullPath
            });
            setIsEditFileModalOpen(true);
        }else {
            const error = fileContentResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al realizar la acción, por favor inténtalo nuevamente.', 3.5);
        }

        setLoadingEditFileModal(false);
        setLoading(false);

    }

    const handleEditFileModalClose = () => {
        if(!loadingEditFileModal) {
            setIsEditFileModalOpen(false);
            setCurrentSelectedFile(undefined);
        }
    }

    const handleEditFileModalOk = async (key: string, newContent: string) => {
        if(!loadingEditFileModal) {
            setLoadingEditFileModal(true);
            const saveFileContentResponse = await MainService.saveFileContent(key, newContent);

            if(saveFileContentResponse.success) {
                messageApi.success('Se guardaron los cambios satisfactoriamente.', 3.5);
                handleEditFileModalClose();
            }else {
                const error = saveFileContentResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al realizar la acción, por favor inténtalo nuevamente.', 3.5);
            }
        }
    }

    return (
        <>
            { contextHolder }

            <Layout breadcrumb={[
                { title: <span><FileTextOutlined /> Update Secrets</span> }
            ]}>
                <Card loading={initLoading}>
                    {
                        !(currentFile && currentFile.type === FileModelType.DOCUMENT) && (
                            <List
                                <FileModel>
                                header={(
                                    <div>
                                        <Breadcrumb
                                            separator="/"
                                            items={
                                                currentFile ? [
                                                    { title: '~', href: '/file-editor' },
                                                    ...currentFile.breadCrumb.map((record) => {
                                                        return {
                                                            title: record.label
                                                        };
                                                    })
                                                ] : [{ title: '~/' }]
                                            }
                                        />
                                    </div>
                                )}
                                bordered
                                dataSource={(currentFile ? [
                                    {
                                        key: '...',
                                        type: FileModelType.BACK_FOLDER,
                                        breadCrumb: [],
                                        fullPath: '/',
                                    },
                                    ...files
                                ] : files)}
                                renderItem={(item) => (
                                    <List.Item key={item.key}>
                                    <span className="file-editor-file-row" onClick={() => { selectFile(item) }}>
                                        {
                                            [FileModelType.FOLDER, FileModelType.BACK_FOLDER].includes(item.type)  ? (<FolderOpenOutlined style={{ marginRight: '5px', color: 'darkgoldenrod' }}/>) : (<FileOutlined style={{ marginRight: '5px' }}/>)
                                        }
                                        {item.key}
                                    </span>
                                    </List.Item>
                                )}
                                loading={loading}
                            />
                        )
                    }
                </Card>

                {/* Modals */}
                {
                    currentSelectedFile && (
                        <EditFileModal
                            selectedFile={currentSelectedFile}
                            handleModalClose={handleEditFileModalClose}
                            isModalOpen={isEditFileModalOpen}
                            loading={loadingEditFileModal}
                            handleModalOK={handleEditFileModalOk}
                        />
                    )
                }
            </Layout>
        </>
    );
}

export default FileEditor;
