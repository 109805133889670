import React, {useEffect, useState} from 'react';
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from '@uiw/react-codemirror';
import { bbedit } from '@uiw/codemirror-themes-all';
import {Alert} from "antd";

interface CodeEditorProps {
    fileContent: string;
    setFileContent: (newFileContent: string) => void;
    extension: string;
}

function CodeEditor({ fileContent, extension, setFileContent }: CodeEditorProps) {
    const [extensions, setExtensions] = useState<any>([]);

    const allExtensions: { [key: string]: any } = {
        // apl: langs.apl,
        // asciiArmor: langs.asciiArmor,
        // asterisk: langs.asterisk,
        // c: langs.c,
        // csharp: langs.csharp,
        // scala: langs.scala,
        // solidity: langs.solidity,
        // kotlin: langs.kotlin,
        // shader: langs.shader,
        // nesC: langs.nesC,
        // objectiveC: langs.objectiveC,
        // objectiveCpp: langs.objectiveCpp,
        // squirrel: langs.squirrel,
        // ceylon: langs.ceylon,
        // dart: langs.dart,
        // cmake: langs.cmake,
        // cobol: langs.cobol,
        // commonLisp: langs.commonLisp,
        // crystal: langs.crystal,
        // cypher: langs.cypher,
        // d: langs.d,
        // diff: langs.diff,
        // dtd: langs.dtd,
        // dylan: langs.dylan,
        // ebnf: langs.ebnf,
        // ecl: langs.ecl,
        // eiffel: langs.eiffel,
        // elm: langs.elm,
        // factor: langs.factor,
        // fcl: langs.fcl,
        // forth: langs.forth,
        // fortran: langs.fortran,
        // gas: langs.gas,
        // gherkin: langs.gherkin,
        // groovy: langs.groovy,
        // haskell: langs.haskell,
        // haxe: langs.haxe,
        // http: langs.http,
        // idl: langs.idl,
        // jinja2: langs.jinja2,
        // mathematica: langs.mathematica,
        // mbox: langs.mbox,
        // mirc: langs.mirc,
        // modelica: langs.modelica,
        // mscgen: langs.mscgen,
        // mumps: langs.mumps,
        // nsis: langs.nsis,
        // ntriples: langs.ntriples,
        // octave: langs.octave,
        // oz: langs.oz,
        // pig: langs.pig,
        env: langs.properties,
        // protobuf: langs.protobuf,
        // puppet: langs.puppet,
        // q: langs.q,
        // sas: langs.sas,
        scss: langs.sass,
        // liquid: langs.liquid,
        // mermaid: langs.mermaid,
        // nix: langs.nix,
        // svelte: langs.svelte,
        // sieve: langs.sieve,
        // smalltalk: langs.smalltalk,
        // solr: langs.solr,
        // sparql: langs.sparql,
        // spreadsheet: langs.spreadsheet,
        // stex: langs.stex,
        // textile: langs.textile,
        // tiddlyWiki: langs.tiddlyWiki,
        // tiki: langs.tiki,
        // troff: langs.troff,
        // ttcn: langs.ttcn,
        // turtle: langs.turtle,
        // velocity: langs.velocity,
        // verilog: langs.verilog,
        // vhdl: langs.vhdl,
        // webIDL: langs.webIDL,
        // xQuery: langs.xQuery,
        // yacas: langs.yacas,
        // z80: langs.z80,
        // wast: langs.wast,
        js: langs.javascript,
        jsx: langs.jsx,
        ts: langs.typescript,
        tsx: langs.tsx,
        vue: langs.vue,
        // angular: langs.angular,
        json: langs.json,
        html: langs.html,
        css: langs.css,
        py: langs.python,
        md: langs.markdown,
        xml: langs.xml,
        sql: langs.sql,
        mysql: langs.mysql,
        pgsql: langs.pgsql,
        java: langs.java,
        // rust: langs.rust,
        cpp: langs.cpp,
        // lezer: langs.lezer,
        php: langs.php,
        // go: langs.go,
        // shell: langs.shell,
        // lua: langs.lua,
        // swift: langs.swift,
        // tcl: langs.tcl,
        // yaml: langs.yaml,
        // vb: langs.vb,
        // powershell: langs.powershell,
        // brainfuck: langs.brainfuck,
        // stylus: langs.stylus,
        // erlang: langs.erlang,
        // nginx: langs.nginx,
        // perl: langs.perl,
        // ruby: langs.ruby,
        // pascal: langs.pascal,
        // livescript: langs.livescript,
        // less: langs.less,
        // scheme: langs.scheme,
        // toml: langs.toml,
        // vbscript: langs.vbscript,
        // clojure: langs.clojure,
        // coffeescript: langs.coffeescript,
        // julia: langs.julia,
        // dockerfile: langs.dockerfile,
        // r: langs.r,
    };

    useEffect(() => {
        const extensionData = allExtensions[extension?.toLowerCase() || ''];
        setExtensions(extensionData ? [extensionData()] : []);
    }, [extension]);

    const getAllSupportedExtensions = (): string[] => {
        return Object.keys(allExtensions);
    }

    const onChange = React.useCallback((val: string) => {
        setFileContent(val);
    }, []);

    return (
        <>
            <Alert style={{ marginBottom: '10px' }} message={<span>El formato del editor se activa para las extensiones: <b>{getAllSupportedExtensions().join(', ')}</b></span>} type="info" showIcon />

            <ReactCodeMirror
                value={fileContent}
                basicSetup={{
                    foldGutter: false,
                    dropCursor: false,
                    allowMultipleSelections: false,
                    indentOnInput: false,
                }}
                extensions={extensions}
                theme={bbedit}
                minHeight="300px"
                maxHeight="70vh"
                placeholder="Documento vacío"
                onChange={onChange}
            />
        </>
    );
}

export default CodeEditor;
