import React from 'react';
import {Navigate, useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Unauthorized from '../../scenes/Unauthorized/Unauthorized';
import { hasRoutePermissions } from '../../utils/AuthUtils';
import {User} from "../../models/User";
import {DEFAULT_GUEST_ROUTE} from "../../config/Config";

type PrivateRouteProps = {
  outlet: JSX.Element;
  permissionsRequired?: string | string[];
};

function PrivateRoute({ outlet, permissionsRequired = [] }: PrivateRouteProps) {
  const user: User = useSelector((state: any) => state.auth);
  const params = useParams();

  if (user?.id !== 0 && hasRoutePermissions(permissionsRequired, user)) {
    return outlet;
  } else if (
      user?.id !== 0 &&
      !hasRoutePermissions(permissionsRequired, user)
  ) {
    return <Unauthorized />;
  }

  return <Navigate to={DEFAULT_GUEST_ROUTE} />;
}

export default PrivateRoute;