import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import AuthAction from '../../redux/actions/authActions/Actions';
import { AuthService } from '../../services/AuthService';
import {
    message,
    Spin,
    Form,
    Checkbox,
    Button,
    Input,
    Typography,
    ConfigProvider
} from 'antd';
import { User } from '../../models/User';
import Password from "antd/es/input/Password";
import {ApiErrorData} from "../../models/ApiResponse";
import esES from "antd/locale/es_ES";
import Paragraph from "antd/es/typography/Paragraph";

import './Login.scss';

function Login() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = async (values: any) => {
        if(!loading) {
            const loadingMessageKey = 'loading-message';
            messageApi.open({
                key: loadingMessageKey,
                type: 'loading',
                content: 'Comprobando credenciales...',
                duration: 0
            });
            setLoading(true);

            const loginResponse = await AuthService.login({ username: values.username, password: values.password });

            messageApi.destroy(loadingMessageKey);

            if(loginResponse.success) {
                messageApi.success('Autenticó sus credenciales con éxito.', 3.5);
                const currentUser = loginResponse.data as User;
                dispatch(AuthAction.signIn(currentUser));
            }else{
                const error = loginResponse.data as ApiErrorData;
                messageApi.error(error.message as string || 'Hubo un error al intentar autenticar al usuario, por favor inténtalo nuevamente.', 3.5);    
            }

            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        messageApi.error('Por favor corrija los errores mencionados', 3.5);
    };

  return (
      <>
          { contextHolder }
          <ConfigProvider locale={esES} theme={{
              token: {
                  colorPrimary: '#707070'
              }
          }}>
              <Spin spinning={loading}>
                  <div className="login-page">
                      <div className="login-box">
                          <div className="login-logo-container">
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <img className="login-logo-img" src="logo_touchlatam.png" alt="logo"/>
                              </div>
                              <div>
                                  <Typography.Title level={3} style={{ textAlign: 'center', color: '#707070' }}>¡Te estábamos esperando!</Typography.Title>
                                  <Paragraph style={{ textAlign: 'center', color: '#707070' }}>Ingrese su nombre de usuario y contraseña para ingresar a nuestro sistema.</Paragraph>
                              </div>
                          </div>

                          <Form
                              name="login-form"
                              initialValues={{ remember: true }}
                              onFinish={onFinish}
                              onFinishFailed={onFinishFailed}
                          >
                              <Form.Item
                                  name="username"
                                  rules={[
                                      { required: true, message: 'El correo electrónico es requerido' },
                                      // { type: 'email', message: 'Debe de ingresar un correo electrónico válido' },
                                  ]}
                              >
                                  <Input
                                      placeholder="Usuario"
                                  />
                              </Form.Item>

                              <Form.Item
                                  name="password"
                                  rules={[
                                      { required: true, message: 'La contraseña es requerida' },
                                      { min: 4, message: 'La contraseña debe de tener un mínimo de 4 caracteres' },
                                  ]}
                              >
                                  <Password
                                      placeholder="Contraseña"
                                  />
                              </Form.Item>

                              <Form.Item name="remember" valuePropName="checked">
                                  <Checkbox>Recuérdame</Checkbox>
                              </Form.Item>

                              <Form.Item>
                                  <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                                      INICIAR SESIÓN
                                  </Button>
                              </Form.Item>
                          </Form>
                      </div>
                  </div>
              </Spin>
          </ConfigProvider>
      </>
  );
}

export default Login;
