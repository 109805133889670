import { REMOVE, SAVE, UPDATE } from "../actions/menuActions/ActionTypes";
import {Action} from "../models/Action";

export interface MenuStore {
  selectedKeys: string[];
  openKeys: string[];
}

const defaultStore: MenuStore = {
  selectedKeys: [],
  openKeys: [],
};

export default function MenuReducer(
  state: MenuStore = defaultStore,
  action: Action
) {
  switch (action.type) {
    case SAVE:
      return {
        selectedKeys: action.payload.selectedKeys,
        openKeys: action.payload.openKeys,
      };
    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE:
      return {
        ...defaultStore
      };
    default:
      return state;
  }
}