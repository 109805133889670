export const USER_TYPES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    REGULAR_ACCOUNT: 'REGULAR_ACCOUNT'
}

export enum FileModelType {
    DOCUMENT = 'DOCUMENT',
    FOLDER = 'FOLDER',
    BACK_FOLDER = 'BACK_FOLDER',
}
