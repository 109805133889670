import React from 'react';
import {Spin} from "antd";

import './LoadingScreen.scss';

interface LoadingScreenSchema {
  loading?: boolean;
  text?: string;
}

function LoadingScreen({ loading = true, text }: LoadingScreenSchema) {

  return (
      <Spin spinning={loading} fullscreen tip={text ? text : undefined} />
  );
}

export default LoadingScreen;
