import {
    FileTextOutlined,
    GithubOutlined
} from "@ant-design/icons";
import {MenuItem} from "../models/MenuItem";
import {User} from "../models/User";

interface MenuConfigSchema {
    menus: MenuItem[];
}

const filterMenus = (menus: MenuItem[]): MenuItem[] => {
    return menus
        .filter(menu => !menu.hidden)
        .map(menu => ({
            ...menu,
            children: menu.children ? filterMenus(menu.children) : undefined,
        }));
};


export const menuConfig = (user: User): MenuConfigSchema => {
    const baseMenus: MenuItem[] = [
        {
            key: '1',
            path: '/file-editor',
            label: 'Update Secrets',
            icon: <FileTextOutlined />
        },
        {
            key: '2',
            path: '/github-action',
            label: 'Github Actions',
            icon: <GithubOutlined />
        }
    ];

    return {
        menus: filterMenus(baseMenus)
    };
};
